import React, { useState } from 'react';
import { unstable_useIsFocusVisible as useIsFocusVisible } from '@mui/utils';
import PropTypes from 'prop-types';

const FocusVisible = ({ children, className, onFocus, onBlur, ...props }) => {
  const { isFocusVisibleRef, onFocus: handleFocusVisible, onBlur: handleBlurVisible } = useIsFocusVisible();
  const [focusVisible, setFocusVisible] = useState(false);

  const handleFocus = (event) => {
    handleFocusVisible(event);
    if (isFocusVisibleRef.current) {
      setFocusVisible(true);
    }
    if (onFocus) onFocus(event);
  };

  const handleBlur = (event) => {
    handleBlurVisible(event);
    setFocusVisible(false);
    if (onBlur) onBlur(event);
  };


  return React.cloneElement(children, {
    className: `${children.props.className || ''} ${className} ${focusVisible ? 'focus-visible' : ''}`.trim(),
    onFocus: handleFocus,
    onBlur: handleBlur,
    ...props,
  });
};

FocusVisible.propTypes = {
  children: PropTypes.element.isRequired,
  className: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
};

FocusVisible.defaultProps = {
  className: '',
  onFocus: null,
  onBlur: null,
};

export default FocusVisible;
