
import React from 'react';
import back from '../assets/icons/back.svg'
import Nav from "./NavBar";

function Terms() {
   const Back = () => {
      window.history.go(-1)
  }
   return(<>

    
    <div>
          
         <input type='image'
            class="d-none d-lg-block back pointer" style={{ 'width': '50px', 'margin-left': '3%' }}
            src={back} onClick={() => Back()}
            alt="back" />

        <h2>terms</h2>
      
      </div>
    </>
   ) 
}
export default Terms;